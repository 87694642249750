<template>
  <main-layout>
    <h1>
      {{
        failed.length === 0
          ? $t("bookedAppointment")
          : $t("AnErrorOccurredWhileBookingOneOrMoreAppointments")
      }}
    </h1>
    <div class="row">
      <div class="col-xxl-6">
        <div v-for="item in failed" :key="item.time">
          <after-booked-item :booked-information="item" :successes="false" />
        </div>
        <div v-for="item in successfully" :key="item.time">
          <after-booked-item :booked-information="item" :successes="true" />
        </div>
        <div class="afterBookPage__total">
          {{ $t("total") }} <span>€{{ coast.toFixed(2) }}</span>
        </div>
      </div>
      <div class="col-xxl-6">
        <div class="afterBookPage__info">
          <h5>{{ $t("bookingInformation") }}</h5>
          <p>
            {{ $t("bookingInformationFirst") }}
          </p>
          <p>
            {{ $t("bookingInformationSecond") }}
          </p>
          <div class="afterBookPage__btn">
            <base-button :calendar="true" link="/"
              >{{ $t("backToHome") }}
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import BaseButton from "@/components/UI/buttons/BaseButton";
import AfterBookedItem from "@/components/elements/card/AfterBookedItem";

export default {
  name: "AfterBookPage",
  components: { AfterBookedItem, BaseButton, MainLayout },
  data() {
    return {
      successfully: [],
      failed: [],
    };
  },
  mounted() {
    this.getBookedStatus();
  },
  methods: {
    getBookedStatus() {
      const booked = this.$store.getters.BOOKED_STATUS;
      this.successfully =
        booked.success && booked.success.length > 0 ? booked.success : [];
      this.failed =
        booked.failed && booked.failed.length > 0 ? booked.failed : [];
    },
  },
  computed: {
    coast() {
      let value = this.successfully;
      let coast = 0;
      for (let i in value) {
        coast = Number(coast) + Number(value[i].price);
      }
      return coast;
    },
  },
};
</script>

<style></style>
