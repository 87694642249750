<template>
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="30" height="30" rx="15" fill="#50AE55" fill-opacity="0.4"/>
        <path d="M12.7138 21L7 15.2863L8.14522 14.1411L12.7138 18.71L22.4242 9L23.5694 10.1452L12.7138 21Z"
              fill="white"/>
    </svg>
</template>

<script>
export default {
    name: "SuccessesBookedIcon"
}
</script>

<style>

</style>
