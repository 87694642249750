<template>
  <main-layout>
    <div class="notFound">
      <div class="notFound__oops">Oops</div>
      <div class="notFound__textRow">
        <div class="notFound__text">
          {{ $t("Error404") }}
        </div>
        <div class="notFound__text">
          {{ $t("PageNotFound") }}
        </div>
      </div>
      <div class="notFound__btns">
        <base-button link="/">
          {{ $t("GoHome") }}
        </base-button>
        <base-button @click="$router.back()">
          {{ $t("GoBack") }}
        </base-button>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import BaseButton from "@/components/UI/buttons/BaseButton.vue";

export default {
  name: "NotFound",
  components: { BaseButton, MainLayout },
};
</script>

<style scoped></style>
