<template>
  <div class="afterBookedItem">
    <div class="afterBookedItem__icon">
      <successes-booked-icon v-if="successes" />
      <fail-booked-icon v-else />
    </div>
    <div class="d-flex align-items-center justify-content-start mb-20">
      <div class="afterBookedItem__service">
        {{ bookedInformation.language }}
      </div>
      <div class="afterBookedItem__lang">
        {{ bookedInformation.service_name }}
      </div>
    </div>
    <div class="row align-items-center mb-20">
      <div class="col-6">
        <div class="d-flex align-items-center">
          <div class="mr-15">
            <avatar
              user-role="experts"
              :user-code="bookedInformation.expert_code"
              :image-name="bookedInformation.expert_image"
              size="100"
              width="60px"
              height="60px"
            />
          </div>
          <div>
            <div class="afterBookedItem__name">
              <short-name
                :name="`${bookedInformation.expert_first_name} ${bookedInformation.expert_last_name}`"
              />
            </div>
            <div class="afterBookedItem__position">
              {{ bookedInformation.expert_position }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-5">
        <div>
          <div class="afterBookedItem__date">
            <data-formatted
              date-format="dd MMM yyyy"
              :data="bookedInformation.time"
            />
          </div>
          <div class="afterBookedItem__time">
            <data-formatted
              date-format="HH:mm"
              :data="bookedInformation.time"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-6">
        <div class="afterBookedItem__type">
          {{ bookedInformation.consultation_name }}
        </div>
        <div class="afterBookedItem__duration">
          Duration:
          <duration-time :data="bookedInformation.duration" />
        </div>
      </div>
      <div class="col-5">
        <div class="afterBookedItem__coast">€{{ bookedInformation.price }}</div>
      </div>
    </div>
    <div v-if="!successes" class="afterBookedItem__fail">
      <p>
        {{ bookedInformation.error_message }}
      </p>
      <div>
        {{ $t("bookedPlease") }} <span>{{ $t("contact") }}</span>
        {{ $t("bookedPageBook") }}
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/UI/images/Avatar";
import DataFormatted from "@/components/components-helpers/DataFormatted";
import ShortName from "@/components/components-helpers/ShortName";
import DurationTime from "@/components/components-helpers/DurationTime";
import SuccessesBookedIcon from "@/components/UI/icons/cartIcons/SucssesBookedIcon";
import FailBookedIcon from "@/components/UI/icons/cartIcons/FailBookedIcon";

export default {
  props: {
    bookedInformation: {},
    successes: Boolean,
  },
  name: "AfterBookedItem",
  components: {
    FailBookedIcon,
    SuccessesBookedIcon,
    DurationTime,
    ShortName,
    DataFormatted,
    Avatar,
  },
};
</script>

<style></style>
